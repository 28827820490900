/* eslint-disable camelcase */
import axios from 'axios';
import { isAxiosError } from '../utils/axios';
import { TimeFrame } from '../utils/time';
import { Order } from '../app/domain/Order';
import { AccessPointValue } from '../app/domain/AccessPoint';
import { ICommunityValue } from '../app/domain/ICommunity';
import { GateUsageReportFilter, IAccessPointUsageReport } from '../features/community/dashboard/activityAnalyticSlice';
import { toUrlSearchParams } from '../utils/web';
import { ServerError } from './ServerError';
import { ICamera } from '../app/domain/ICamera';
import { GateErrorStatItem, GateErrorStatItemValue } from '../features/daily_errors/GateErrorStatItem';
import {
  UnusedAccessPointsReportItem,
  UnusedAccessPointsReportItemValue,
} from '../features/unused_access_points_report/UnusedAccessPointsReportItem';
import {
  UnusedCommunityReportItem,
  UnusedCommunityReportItemValue,
} from '../features/unused_access_points_report/unusedCommunityReportItem';
import { IAccessLogReport } from '../features/access_log/accessLogsSlice';

import { IThirdPartyUnit } from '../models/IThirdPartyUnit';
import { IApiKey } from '../models/apiKey';
import {
  BookedTourStatus,
  IBookedTour,
  ITour,
  ITourAccessPoint,
  ITourAccessPointData,
  ITourCreate,
  ITourUnit,
  ITourUnitData,
  ITourUpdatePayload,
  ITourUnitDataExtendedUnit,
} from '../app/domain/ITour';

const API_PREFIX = process.env.API_URL_CLUSTER;
const API_V3_PREFIX = process.env.API_V3_URL;

type ErrorListener = (error: unknown) => void;
const errorListeners: ErrorListener[] = [];
type OnRequestFn = (conf: unknown) => void;
let onRequest: OnRequestFn | null = null;

function mapError(e: unknown) {
  return isAxiosError(e) ? ServerError.fromAxios(e) : e;
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    errorListeners.forEach((e) => e(error));
    return Promise.reject(error);
  },
);

axios.interceptors.request.use(
  (requestConf) => {
    if (onRequest != null) {
      return onRequest(requestConf);
    }
    return requestConf;
  },
  (error) => Promise.reject(error),
);

export function setRequestListener(l: OnRequestFn) {
  onRequest = l;
}

export function addErrorListener(l: ErrorListener) {
  errorListeners.push(l);
}

export function setAuthToken(token: string) {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
}

export async function getCurrentUser() {
  try {
    const { data } = await axios.get(`${API_PREFIX}/user/current`);
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function listJobs() {
  const {
    data: { data },
  } = await axios.get(`${API_PREFIX}/admin/job`);
  return data;
}

export async function deleteJob(id: string) {
  const { data } = await axios.delete(`${API_PREFIX}/admin/job/${id}`);
  return data;
}

export async function deleteRepeatableJob(key: string) {
  const { data } = await axios.post(`${API_PREFIX}/admin/job/repeatable`, { key });
  return data;
}

export async function scheduleAllJobs() {
  const { data } = await axios.post(`${API_PREFIX}/admin/job/schedule_all`);
  return data;
}

export async function schedule3rdPartyCommunitySync(communityId: string) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityId}/job/schedule3rdPartyCommunitySync`);
  return data;
}

export async function listCommunities(offset: number, limit: number, search: string, abortSignal?: AbortSignal) {
  let request = `${API_PREFIX}/community?offset=${offset}&limit=${limit}`;
  if (search) {
    request = `${request}&search=${search}`;
  }
  const {
    data: { data },
  } = await axios.get(request, {
    signal: abortSignal,
  });
  return data;
}

export async function getCommunityDetails(id: string) {
  const {
    data: { data },
  } = await axios.get(`${API_PREFIX}/community/${id}`);
  return data;
}

export async function addCommunity(community: unknown) {
  const { data } = await axios.post(`${API_PREFIX}/community`, community);
  return data;
}

export async function updateCommunity(id: string, community: unknown) {
  const { data } = await axios.put(`${API_PREFIX}/community/${id}`, community);
  return data;
}

export async function patchCommunity(id: string, community: Partial<ICommunityValue>) {
  try {
    const { data } = await axios.patch(`${API_PREFIX}/community/${id}`, community);
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function updateCommunityGeo(id: string, geo: unknown) {
  const { data } = await axios.put(`${API_PREFIX}/community/${id}/update_geo`, geo);
  return data;
}

export async function deleteCommunity(communityID: string) {
  const { data } = await axios.delete(`${API_PREFIX}/community/${communityID}`);
  return data;
}

export interface IListCompaniesQuery {
  search?: string;
  offset?: number;
  limit?: number;
  signal?: AbortSignal;
  includeId?: string | number | null;
}

export const listCompanies = async (query: IListCompaniesQuery = { search: '', offset: 0, limit: 300 }) => {
  const { search, limit, offset, signal, includeId } = query;
  let request = `${API_PREFIX}/admin/management_company?limit=${limit}&offset=${offset}`;

  if (search) {
    request += `&search=${search}`;
  }
  if (includeId) {
    request += `&includeId=${includeId}`;
  }

  const {
    data: { data },
  } = await axios.get(request, { signal });
  return data;
};

export const addCompany = async (company: unknown) => {
  const { data } = await axios.post(`${API_PREFIX}/admin/management_company`, company);
  return data;
};

export const updateCompany = async (id: string, company: unknown) => {
  const { data } = await axios.put(`${API_PREFIX}/admin/management_company/${id}`, company);
  return data;
};

export async function deleteCompany(companyID: string) {
  const { data } = await axios.delete(`${API_PREFIX}/admin/management_company/${companyID}`);
  return data;
}

// Global admin members
export async function listGlobalMembers(query: {
  offset: number;
  limit: number;
  filter: number;
  invitationStatusFilter: unknown;
  search: string;
  active: boolean;
  lastSeen: boolean;
  abortSignal?: AbortSignal;
}) {
  const request = `${API_PREFIX}/admin/member`;
  const {
    data: { data },
  } = await axios.get(request, {
    signal: query.abortSignal,
    params: {
      filter: query.filter,
      search: query.search,
      active: query.active,
      invitationStatus: query.invitationStatusFilter,
      lastSeen: query.lastSeen,
      offset: query.offset ? query.offset : 0,
      limit: query.limit,
    },
  });
  return data;
}

export const listDevices = async (offset: number, limit: number, params: object, abortSignal?: AbortSignal) => {
  const {
    data: { data },
  } = await axios.get(`${API_PREFIX}/admin/access_point`, {
    signal: abortSignal,
    params: { offset: offset ? offset : 0, limit, ...params },
  });
  return data;
};

export const getAccessPoint = async (id: string) => {
  const {
    data: { data },
  } = await axios.get(`${API_PREFIX}/admin/access_point/${id}`);
  return data;
};

export const updateDailyReportItem = async (
  id: string,
  value: Partial<GateErrorStatItemValue>,
): Promise<GateErrorStatItem> => {
  try {
    const { data } = await axios.patch(`${API_PREFIX}/admin/daily_errors/${id}`, value);
    return {
      ...data,
      comment_updated_at: data.comment_updated_at
        ? new Date(data.comment_updated_at).getTime()
        : data.comment_updated_at,
    };
  } catch (e) {
    throw mapError(e);
  }
};

export const listGsmVersions = async () => {
  const {
    data: { data },
  } = await axios.get(`${API_PREFIX}/admin/gsm_version`);
  return data;
};

export async function listDevicesEvents(query: {
  offset: number;
  limit: number;
  search: string;
  group_by: string;
  start_time: unknown;
  power_filter: unknown;
  rssi_threshold: unknown;
  abortSignal?: AbortSignal;
}) {
  const {
    data: { data },
  } = await axios.get(`${API_PREFIX}/admin/device_event`, {
    signal: query.abortSignal,
    params: {
      offset: query.offset ? query.offset : 0,
      limit: query.limit,
      search: query.search,
      group_by: query.group_by,
      start_time: query.start_time,
      power_filter: query.power_filter,
      rssi_threshold: query.rssi_threshold,
    },
  });
  return data;
}

export async function listDevicesOpenEvents(query: {
  offset: number;
  limit: number;
  search: string;
  group_by: string;
  start_time: unknown;
  abortSignal?: AbortSignal;
}) {
  const {
    data: { data },
  } = await axios.get(`${API_PREFIX}/admin/device_open_events`, {
    signal: query.abortSignal,
    params: {
      offset: query.offset ? query.offset : 0,
      limit: query.limit,
      search: query.search,
      group_by: query.group_by,
      start_time: query.start_time,
    },
  });
  return data;
}

export async function deleteGlobalInvites(ids: string[]) {
  const { data } = await axios.post(`${API_PREFIX}/admin/invite/delete`, { ids });
  return data;
}

export async function deleteGlobalMembers(ids: string[]) {
  const { data } = await axios.post(`${API_PREFIX}/admin/member/delete`, { ids });
  return data;
}

export async function activateGlobalMembers(ids: string[]) {
  const { data } = await axios.post(`${API_PREFIX}/admin/member/activate`, { ids });
  return data;
}

export async function deactivateGlobalMembers(ids: string[]) {
  const { data } = await axios.post(`${API_PREFIX}/admin/member/deactivate`, { ids });
  return data;
}

// Community Members
export async function listMembers(
  communityID: string,
  offset: number,
  limit: number,
  filter: unknown,
  invitationStatusFilter: unknown,
  lastSeen: boolean,
  search: string,
  active: boolean,
  orderBy: string,
  orderDirection: string,
  signal: AbortSignal,
) {
  const request = `${API_PREFIX}/community/${communityID}/member`;
  const {
    data: { data },
  } = await axios
    .get(request, {
      signal: signal,
      params: {
        filter,
        search,
        active,
        invitationStatus: invitationStatusFilter,
        lastSeen,
        offset: offset ? offset : 0,
        limit: limit ? limit : 10,
        orderBy,
        orderDirection,
      },
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return { data: { data: [] } };
      } else {
        return Promise.reject(error);
      }
    });
  return data;
}

export async function getMember(communityID: string, id: string) {
  const {
    data: { data },
  } = await axios.get(`${API_PREFIX}/community/${communityID}/member/${id}`);
  return data;
}

export async function inviteMembers(communityID: string, members: object[]) {
  try {
    const { data } = await axios.patch(`${API_PREFIX}/community/${communityID}/invite`, { members });
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function getInvitedMember(communityID: string, id: string) {
  const {
    data: { data },
  } = await axios.get(`${API_PREFIX}/community/${communityID}/invite/${id}`);
  return data;
}

export async function inviteMember(communityID: string, member: object) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/invite`, member);
  return data;
}

export async function checkIfMemberExists(communityID: string, email: string) {
  const { data } = await axios.get(`${API_PREFIX}/community/${communityID}/invite/email/${email}`);
  return data;
}

export async function deleteInvites(communityID: string, ids: string[]) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/invite/delete`, { ids });
  return data;
}

export async function updateInvite(communityID: string, id: string, invite: object) {
  const { data } = await axios.put(`${API_PREFIX}/community/${communityID}/invite/${id}`, invite);
  return data;
}

export async function generatePassword(id: string, invited: boolean) {
  const request = invited
    ? `${API_PREFIX}/admin/invite/${id}/generate_password`
    : `${API_PREFIX}/admin/member/${id}/generate_password`;
  const { data } = await axios.post(request);
  return data;
}

export async function updateMember(communityID: string, id: string, member: object) {
  const { data } = await axios.put(`${API_PREFIX}/community/${communityID}/member/${id}`, member);
  return data;
}

export async function updatePhoneNumber(communityID: string, id: string, phone: string) {
  const { data } = await axios.put(`${API_PREFIX}/community/${communityID}/member/${id}/phone`, { phone });
  return data;
}

export async function deleteMembers(communityID: string, ids: string[]) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/member/delete`, { ids });
  return data;
}

export async function activateMembers(communityID: string, ids: string[]) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/member/activate`, { ids });
  return data;
}

export async function deactivateMembers(communityID: string, ids: string[]) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/member/deactivate`, { ids });
  return data;
}

export async function resetMemberTokensCount(communityID: string, ids: string[]) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/member/resetTokensCount`, { ids });
  return data;
}

export async function listGates(communityID: string) {
  const request = `${API_PREFIX}/community/${communityID}/access_point`;
  const {
    data: { data },
  } = await axios.get(request);
  return data;
}

export async function listGateTypes(communityID: string, offset = 0, limit = 1000) {
  const request = `${API_PREFIX}/community/${communityID}/access_point_type?offset=${offset}&limit=${limit}`;
  const {
    data: { data },
  } = await axios.get(request);
  return data;
}

export async function addGate(communityID: string, gate: AccessPointValue) {
  try {
    const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/access_point`, gate);
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function findGateById(communityID: string, id: string) {
  try {
    const { data } = await axios.get(`${API_PREFIX}/community/${communityID}/access_point/${id}`);
    return data.data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function updateGate(communityID: string, id: string, gate: object) {
  try {
    const { data } = await axios.put(`${API_PREFIX}/community/${communityID}/access_point/${id}`, gate);
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function deleteGate(communityID: string, id: string) {
  const { data } = await axios.delete(`${API_PREFIX}/community/${communityID}/access_point/${id}`);
  return data;
}

export const openGate = async (communityId: string, id: string) => {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityId}/access_point/${id}/open`);
  return data;
};

export const syncGate = async (communityId: string, id: string) => {
  try {
    const { data } = await axios.put(`${API_PREFIX}/community/${communityId}/access_point/${id}/sync`);
    return data;
  } catch (e) {
    throw mapError(e);
  }
};

export const resetGate = async (communityID: string, id: string) => {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/access_point/${id}/reset`);
  return data;
};

export const fetchGateLog = async (communityID: string, id: string) => {
  const { data } = await axios.get(`${API_PREFIX}/community/${communityID}/access_point/${id}/fetch_log`);
  return data;
};

export const fetchAccessPointsExistingRemoteRelations = async (communityId: string, id: string) => {
  const {
    data: { data },
  } = await axios.get(`${API_PREFIX}/community/${communityId}/access_point/${id}/relations`);
  return data;
};

export const upgradeDevices = async (serverPath: string, devices: object[]) => {
  const { data } = await axios.post(`${API_PREFIX}/admin/access_point/upgrade`, { server_path: serverPath, devices });
  return data;
};

export const syncDevices = async (gsmIDs: string[]) => {
  const { data } = await axios.post(`${API_PREFIX}/admin/access_point/sync`, { gsm_ids: gsmIDs });
  return data;
};

export async function listGroups(communityID: string, offset: number, limit: number) {
  const request = `${API_PREFIX}/community/${communityID}/group?offset=${offset}&limit=${limit}`;
  const {
    data: { data },
  } = await axios.get(request);
  return data;
}

export async function addGroup(communityID: string, group: object) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/group`, group);
  return data;
}

export async function updateGroup(communityID: string, id: string, group: object) {
  const { data } = await axios.put(`${API_PREFIX}/community/${communityID}/group/${id}`, group);
  return data;
}

export async function deleteGroup(communityID: string, id: number) {
  const { data } = await axios.delete(`${API_PREFIX}/community/${communityID}/group/${id}`);
  return data;
}

export async function addAllMembersToGroup(groupId: string, communityId: string) {
  const { data } = await axios.put(`${API_PREFIX}/community/${communityId}/group/${groupId}/addAll`);
  return data;
}

export async function removeAllMembersFromGroup(groupId: string, communityId: string) {
  const { data } = await axios.put(`${API_PREFIX}/community/${communityId}/group/${groupId}/removeAll`);
  return data;
}

export async function listRemotes(
  communityID: string,
  search: string,
  status: string,
  device_status: string,
  offset: number,
  limit: number,
) {
  const request = `${API_PREFIX}/community/${communityID}/remote`;
  try {
    const { data } = await axios.get(request, {
      params: {
        search,
        status,
        device_status,
        offset: offset ? offset : 0,
        limit,
      },
    });
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function listAvailableRemotes(communityID: string, search: string, offset: number, limit: number) {
  const request = `${API_PREFIX}/community/${communityID}/remote/unassigned`;
  const {
    data: { data },
  } = await axios.get(request, {
    params: {
      search,
      offset: offset ? offset : 0,
      limit,
    },
  });
  return data;
}

export async function addRemote(communityID: string, { serial_number, type }: { serial_number: string; type: string }) {
  try {
    const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/remote/${type}`, {
      value: serial_number,
    });
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function activateRemote(communityID: string, id: string) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/remote/${id}/activate`);
  return data;
}

export async function deactivateRemote(communityID: string, id: string) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/remote/${id}/deactivate`);
  return data;
}

export const syncRemoteWithAccessPoint = async (
  communityId: string,
  serial_number: string,
  access_point_id: string,
) => {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityId}/remote/sync`, {
    serial_number,
    access_point_id,
  });
  return data;
};

export const fetchExistingRemoteAccessPointsRelations = async (communityId: string, serial_number: string) => {
  const {
    data: { data },
  } = await axios.get(`${API_PREFIX}/community/${communityId}/remote/${serial_number}/relations`);
  return data;
};

export async function removeRemoteAccessPointRelation(communityID: string, accessPointID: string, serial: string) {
  const { data } = await axios.delete(
    `${API_PREFIX}/community/${communityID}/access_point/${accessPointID}/relations/${serial}`,
  );
  return data;
}

export async function listGlobaRemotes(
  search: string,
  status: string,
  device_status: string,
  offset: number,
  limit: number,
) {
  const request = `${API_PREFIX}/admin/remote`;
  const {
    data: { data },
  } = await axios.get(request, {
    params: {
      search,
      status,
      device_status,
      offset: offset ? offset : 0,
      limit,
    },
  });
  return data;
}

export async function syncRemote(communityID: string, id: string) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/remote/${id}/sync`);
  return data;
}

export async function deleteRemote(communityID: string, id: string) {
  const { data } = await axios.delete(`${API_PREFIX}/community/${communityID}/remote/${id}`);
  return data;
}

export async function addProspectAccess(communityID: string, params: object) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/prospects_access`, params);
  return data;
}

export async function activateProspectAccess(communityID: string, id: string) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/prospects_access/${id}/activate`);
  return data;
}

export async function deactivateProspectAccess(communityID: string, id: string) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/prospects_access/${id}/deactivate`);
  return data;
}

export async function deleteProspectAccess(communityID: string, id: string) {
  const { data } = await axios.delete(`${API_PREFIX}/community/${communityID}/prospects_access/${id}`);
  return data;
}

export async function listProspectsAccess(
  communityID: string,
  search: string,
  status: string,
  offset: number,
  limit: number,
) {
  const request = `${API_PREFIX}/community/${communityID}/prospects_access`;
  const {
    data: { data },
  } = await axios.get(request, {
    params: {
      search,
      status,
      offset: offset ? offset : 0,
      limit,
    },
  });
  return data;
}

export async function addVendorAccess(communityID: string, params: string) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/vendors_access`, params);
  return data;
}

export async function updateVendorAccess(communityID: string, params: { id: string }) {
  const { data } = await axios.put(`${API_PREFIX}/community/${communityID}/vendors_access/${params.id}`, params);
  return data;
}

export async function activateVendorAccess(communityID: string, id: string) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/vendors_access/${id}/activate`);
  return data;
}

export async function deactivateVendorAccess(communityID: string, id: string) {
  const { data } = await axios.post(`${API_PREFIX}/community/${communityID}/vendors_access/${id}/deactivate`);
  return data;
}

export async function deleteVendorAccess(communityID: string, id: string) {
  const { data } = await axios.delete(`${API_PREFIX}/community/${communityID}/vendors_access/${id}`);
  return data;
}

export async function listVendorsAccess(
  communityID: string,
  search: string,
  status: string,
  offset: number,
  limit: number,
) {
  const request = `${API_PREFIX}/community/${communityID}/vendors_access`;
  const {
    data: { data },
  } = await axios.get(request, {
    params: {
      search,
      status,
      offset: offset ? offset : 0,
      limit,
    },
  });
  return data;
}

export async function listAccessLog(
  communityID: string,
  offset: number,
  limit: number,
  timeFrame: Partial<TimeFrame>,
  search: string | undefined,
  detailed: boolean | undefined,
) {
  try {
    const {
      data: { data },
    } = await axios.get(`${API_PREFIX}/community/${communityID}/access_log`, {
      params: {
        limit,
        offset: offset ? offset : 0,
        detailed: detailed === true,
        search,
        start_date: timeFrame && timeFrame.from ? new Date(timeFrame.from) : undefined,
        end_date: timeFrame && timeFrame.to ? new Date(timeFrame.to) : undefined,
      },
    });
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function gateUsageReport(
  communityID: string,
  { dateRange }: GateUsageReportFilter,
): Promise<IAccessPointUsageReport[]> {
  try {
    const { data } = await axios.get(
      `${API_PREFIX}/community/${communityID}/gate-usage-report?${toUrlSearchParams({
        start_date: dateRange.from,
        end_date: dateRange.to,
      })}`,
    );
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function fetchFirstCameraById(communityId: string): Promise<ICamera | undefined> {
  try {
    const { data } = await axios.get(`${API_PREFIX}/community/${communityId}/camera/first`);
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function listCamerasByCommunity(communityId: string): Promise<ICamera[]> {
  try {
    const { data } = await axios.get(`${API_PREFIX}/community/${communityId}/cameras`);
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function listGlobalAccessLog(
  offset: number,
  limit: number,
  timeFrame: Partial<TimeFrame>,
  search: string | undefined,
) {
  try {
    const {
      data: { data },
    } = await axios.get(`${API_PREFIX}/admin/access_log`, {
      params: {
        limit,
        offset: offset ? offset : 0,
        search,
        start_date: timeFrame && timeFrame.from ? new Date(timeFrame.from) : undefined,
        end_date: timeFrame && timeFrame.to ? new Date(timeFrame.to) : undefined,
      },
    });
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export const reorderAccessPoints = async (communityId: string, order: Order[]) => {
  await axios.put(`${API_PREFIX}/community/${communityId}/access_point/reorder`, { order });
};

export const reorderGroups = async (communityId: string, order: string) => {
  await axios.put(`${API_PREFIX}/community/${communityId}/group/reorder`, { order });
};

export const listDailyErrors = async (failuresPercentThreshold: number) => {
  try {
    const { data } = await axios.get(`${API_PREFIX}/admin/daily_errors`, {
      params: {
        failuresPercentThreshold,
      },
    });
    return data.map((it: any) => ({
      ...it,
      comment_updated_at: it.comment_updated_at ? new Date(it.comment_updated_at).getTime() : it.comment_updated_at,
    }));
  } catch (e) {
    throw mapError(e);
  }
};

export const listGeneralStatistics = async () => {
  try {
    const {
      data: { data },
    } = await axios.get(`${API_PREFIX}/admin/general_statistics`);
    return data;
  } catch (e) {
    throw mapError(e);
  }
};

export const getAppSettings = async () => {
  const {
    data: { settings },
  } = await axios.get(`${API_PREFIX}/admin/settings`);
  return settings;
};

export const updateAppSettings = async (settings: object) => {
  const { data } = await axios.put(`${API_PREFIX}/admin/settings`, settings);
  return data;
};

export const sendSmsToPendingMembers = async (communityId: string, smsBody: string, considerIfAllowed = undefined) => {
  const { data } = await axios.post(
    `${API_PREFIX}/community/${communityId}/sendSmsToPendingMembers`,
    { smsBody },
    { params: { considerIfAllowed } },
  );
  return data;
};

export async function listSyncLogs(communityId: string, limit: number, offset: number) {
  const { data } = await axios.get(`${API_PREFIX}/community/${communityId}/sync_log`, {
    params: { offset: offset ? offset : 0, limit },
  });
  return data;
}

export async function get3rdPartyResponse(communityId: string, type: string) {
  const { data } = await axios.get(`${API_PREFIX}/community/${communityId}/3rd_party_sync_response`, {
    params: { type },
  });
  return data;
}

export const updateGroupUnits = async (communityId: string, groupId: string, units: object[]) => {
  const { data } = await axios.put(`${API_PREFIX}/community/${communityId}/group/${groupId}/units`, { units });
  return data;
};

export const fetchGroupUnits = async (communityId: string) => {
  const {
    data: { data },
  } = await axios.get(`${API_PREFIX}/community/${communityId}/units`);
  return data;
};

// Product updates
export async function listProductUpdates(offset: number, limit: number, search?: string) {
  try {
    const {
      data: { data },
    } = await axios.get(`${API_PREFIX}/product_updates`, { params: { limit, offset: offset ? offset : 0, search } });
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function addProductUpdates(productUpdates: object[]) {
  const { data } = await axios.post(`${API_PREFIX}/product_updates`, productUpdates);
  return data;
}

export async function updateProductUpdates(id: string, productUpdates: object[]) {
  const { data } = await axios.put(`${API_PREFIX}/product_updates/${id}`, productUpdates);
  return data;
}

export async function deleteProductUpdates(id: string) {
  const { data } = await axios.delete(`${API_PREFIX}/product_updates/${id}`);
  return data;
}

export async function listNewProductsTypes(offset = 0, limit = 1000) {
  const request = `
  ${API_PREFIX}/product_updates/types`;
  const params = { limit, offset };
  const {
    data: { data },
  } = await axios.get(request, { params });
  return data;
}

export async function getProductUpdatesViewDate() {
  const { data } = await axios.get(`${API_PREFIX}/product_updates/view_date`);
  return data;
}

export async function addProductUpdatesViewDate() {
  const { data } = await axios.post(`${API_PREFIX}/product_updates/view_date`);
  return data;
}

export async function loadInvoices(communityId: string, offset = 0, limit = 1000) {
  try {
    const request = `${API_PREFIX}/community/${communityId}/quickbooks/invoices`;
    const params = { limit, offset };
    const {
      data: { data },
    } = await axios.get(request, { params });
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function listQuickbooksCustomers(search: string, limit: number) {
  const params = { search, limit };
  const request = `${API_PREFIX}/admin/quickbooks/customers`;
  const {
    data: { data },
  } = await axios.get(request, { params });
  return data;
}

export async function getQuickbooksCustomer(id: string) {
  const params = { id };
  const request = `${API_PREFIX}/admin/quickbooks/customer`;
  const {
    data: { data },
  } = await axios.get(request, { params });
  return data;
}

export async function getInvoicePdf(communityId: string, id: string) {
  const request = `${API_PREFIX}/community/${communityId}/quickbooks/invoices/${id}/getpdf`;
  const {
    data: { data },
  } = await axios.get(request, {});
  return data;
}

export const fetchUnusedAccessPointsReport = async (interval: number): Promise<UnusedAccessPointsReportItem[]> => {
  try {
    const response = await axios.get(`${API_PREFIX}/admin/unused_access_points_report`, { params: { interval } });
    return response.data.map((it: any) => ({
      ...it,
      commentLastUpdated: it.commentLastUpdated ? new Date(it.commentLastUpdated).getTime() : it.commentLastUpdated,
    }));
  } catch (e) {
    throw mapError(e);
  }
};

export const updateUnusedAccessPointItem = async (
  id: string,
  value: Partial<UnusedAccessPointsReportItemValue>,
): Promise<GateErrorStatItem> => {
  try {
    const { data } = await axios.patch(`${API_PREFIX}/admin/unused_access_points_report/${id}`, value);
    return {
      ...data,
      commentLastUpdated: data.commentLastUpdated
        ? new Date(data.commentLastUpdated).getTime()
        : data.commentLastUpdated,
    };
  } catch (e) {
    throw mapError(e);
  }
};

export const fetchCommunityUsageReport = async (offset: number): Promise<UnusedCommunityReportItem[]> => {
  try {
    const response = await axios.get(`${API_PREFIX}/admin/community_usage_report`, { params: { offset } });
    return response.data.map((it: any) => ({
      ...it,
      commentLastUpdated: it.commentLastUpdated ? new Date(it.commentLastUpdated).getTime() : it.commentLastUpdated,
    }));
  } catch (e) {
    throw mapError(e);
  }
};

export const updateUnusedCommunitiesReport = async (id: string, value: Partial<UnusedCommunityReportItemValue>) => {
  try {
    const { data } = await axios.patch(`${API_PREFIX}/admin/unused_communities_report/${id}`, value);
    return {
      ...data,
      commentLastUpdated: data.commentLastUpdated
        ? new Date(data.commentLastUpdated).getTime()
        : data.commentLastUpdated,
    };
  } catch (e) {
    throw mapError(e);
  }
};

export async function startCameraVideoClip(communityId: string, sn: string, ts: number, videoType = 'access_log') {
  const { data } = await axios.get(
    `${API_PREFIX}/community/${communityId}/camera/video/start/${sn}/${ts}/${videoType}`,
  );
  return data;
}

export async function checkUploadedCameraVideo(communityId: string, sn: string, ts: number) {
  const { data } = await axios.get(`${API_PREFIX}/community/${communityId}/camera/video/check/${sn}/${ts}`);
  return data;
}

export async function startCameraLiveStreamAPI(communityId: string, sn: string) {
  const { data } = await axios.get(`${API_PREFIX}/community/${communityId}/camera/live/${sn}`);
  return data;
}

export async function stopCameraLiveStreamAPI(communityId: string, sn: string) {
  try {
    const { data } = await axios.get(`${API_PREFIX}/community/${communityId}/camera/live/stop/${sn}`);
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function checkCameraLiveStreamAPI(communityId: string, sn: string): Promise<{ url: string }> {
  const { data } = await axios.get(`${API_PREFIX}/community/${communityId}/camera/live/check/${sn}`);
  return data;
}

export async function getYardiVersion(url: string): Promise<string> {
  try {
    const { data } = await axios.post(`${API_PREFIX}/admin/yardi/version`, { url });
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function triggerCreateAccessLogReport(communityId: string, { from, to }: TimeFrame): Promise<void> {
  try {
    await axios.post(`${API_PREFIX}/admin/community/${communityId}/access_log_report`, {
      dateFrom: new Date(from).toISOString(),
      dateTo: new Date(to).toISOString(),
    });
  } catch (e) {
    throw mapError(e);
  }
}

export async function fetchAccessLogReportByCommunityId(communityId: string): Promise<IAccessLogReport[]> {
  try {
    const response = await axios.get(`${API_PREFIX}/admin/community/${communityId}/access_log_report`);
    return response.data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function fetchAccessLogReportLinkById(communityId: string, id: string): Promise<string> {
  try {
    const response = await axios.post<{ url: string }>(
      `${API_PREFIX}/admin/community/${communityId}/access_log_report/link`,
      { accessLogReportId: id },
    );
    return response.data.url;
  } catch (e) {
    throw mapError(e);
  }
}

export async function listTours(communityId: string): Promise<ITour[]> {
  try {
    const { data } = await axios.get<{ tours: ITour[] }>(`${API_PREFIX}/community/${communityId}/tours`);
    return data.tours;
  } catch (e) {
    throw mapError(e);
  }
}

export async function updateTourAccessPoints(
  communityId: string | number,
  tourId: string,
  accessPoints: ITourAccessPoint[],
): Promise<void> {
  try {
    await axios.put(`${API_PREFIX}/community/${communityId}/tours/${tourId}/access-points`, {
      accessPoints,
    });
  } catch (e) {
    throw mapError(e);
  }
}

export async function updateTourUnits(communityId: string | number, tourId: string, units: ITourUnit[]): Promise<void> {
  try {
    await axios.put(`${API_PREFIX}/community/${communityId}/tours/${tourId}/units`, { units });
  } catch (e) {
    throw mapError(e);
  }
}

export async function updateAccessPointData(
  communityId: string | number,
  accessPointId: string | number,
  data: ITourAccessPointData,
): Promise<void> {
  try {
    await axios.put(`${API_PREFIX}/community/${communityId}/access-point/${accessPointId}/data`, data);
  } catch (e) {
    throw mapError(e);
  }
}

export async function updateUnitData(unitId: string, communityId: string | number, data: ITourUnitData): Promise<void> {
  try {
    await axios.put(`${API_PREFIX}/community/${communityId}/units/${unitId}/data`, data);
  } catch (e) {
    throw mapError(e);
  }
}

export async function createTour(communityId: string | number, data: ITourCreate) {
  try {
    const { data: createdTour } = await axios.post<Omit<ITour, 'accessPoints' | 'units'>>(
      `${API_PREFIX}/community/${communityId}/tours`,
      data,
    );

    return createdTour;
  } catch (e) {
    throw mapError(e);
  }
}

export async function updateTour(communityId: string | number, tourId: string, data: ITourUpdatePayload) {
  try {
    const { data: createdTour } = await axios.put<Omit<ITour, 'accessPoints' | 'units'>>(
      `${API_PREFIX}/community/${communityId}/tours/${tourId}`,
      data,
    );

    return createdTour;
  } catch (e) {
    throw mapError(e);
  }
}

export async function listDemoApartments(communityId: string): Promise<IThirdPartyUnit[]> {
  try {
    const { data } = await axios.get<IThirdPartyUnit[]>(
      `${API_PREFIX}/community/${communityId}/unit_locks/demo-apartments`,
    );

    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function getApiKey(communityId: string): Promise<IApiKey> {
  try {
    const { data } = await axios.get<IApiKey>(`${API_V3_PREFIX}/admin/community/${communityId}/api-key`);
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function getApiKeyCompany(companyId: string): Promise<IApiKey> {
  try {
    const { data } = await axios.get<IApiKey>(`${API_V3_PREFIX}/admin/company/${companyId}/api-key`);
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

/**
 * This method call an api route for checking and setting the api key community that was added to company that
 * already has a company api key
 * @param companyId
 */
export async function setApiKeyForCommunityByCompanyId(companyId: string, communityId: string): Promise<IApiKey> {
  try {
    const { data } = await axios.post<IApiKey>(
      `${API_V3_PREFIX}/admin/company/${companyId}/api-key/community/${communityId}`,
    );
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function createApiKey(communityId: string): Promise<IApiKey> {
  try {
    const { data } = await axios.post<IApiKey>(`${API_V3_PREFIX}/admin/community/${communityId}/api-key`);
    return data;
  } catch (e: any) {
    if (e.response?.status === 409) {
      throw new Error('Api key is manged by company');
    }
    throw mapError(e);
  }
}

export async function createApiKeyCompany(companyId: string): Promise<IApiKey> {
  try {
    const { data } = await axios.post<IApiKey>(`${API_V3_PREFIX}/admin/company/${companyId}/api-key`);
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function updateApiKey(communityId: string): Promise<IApiKey> {
  try {
    const { data } = await axios.patch<IApiKey>(`${API_V3_PREFIX}/admin/community/${communityId}/api-key`);
    return data;
  } catch (e: any) {
    if (e.response?.status === 409) {
      throw new Error('Api key is manged by company');
    }
    throw mapError(e);
  }
}

export async function updateApiKeyCompany(companyId: string): Promise<IApiKey> {
  try {
    const { data } = await axios.patch<IApiKey>(`${API_V3_PREFIX}/admin/company/${companyId}/api-key`);
    return data;
  } catch (e) {
    throw mapError(e);
  }
}

export async function deleteApiKey(communityId: string): Promise<void> {
  try {
    await axios.delete(`${API_V3_PREFIX}/admin/community/${communityId}/api-key`);
  } catch (e: any) {
    if (e.response?.status === 409) {
      throw new Error('Api key is manged by company');
    }
    throw mapError(e);
  }
}

export async function deleteApiKeyCompany(companyId: string): Promise<void> {
  try {
    await axios.delete(`${API_V3_PREFIX}/admin/company/${companyId}/api-key`);
  } catch (e) {
    throw mapError(e);
  }
}

export async function listBookedTours(
  communityId: string,
  query: {
    limit: number;
    page: number;
    search?: string;
    statuses?: BookedTourStatus[];
    fromDate?: Date;
    toDate?: Date;
  },
) {
  try {
    const result = await axios.get<{ total: number; data: IBookedTour[] }>(
      `${API_PREFIX}/community/${communityId}/booked-tours`,
      {
        params: query,
      },
    );

    return result.data;
  } catch (e) {
    throw mapError(e);
  }
}
