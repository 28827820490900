import { IAvailabilitySchedule } from '../../components/ScheduleV2';

export enum TourDuration {
  ONE_HOUR = 'ONE_HOUR',
  HALF_HOUR = 'HALF_HOUR',
}
export interface ITourAccessPoint {
  id: number;
  sequence: number;
}

export interface ITourAccessPointData {
  isActive: boolean;
  description?: string;
  directions?: string;
  imageUrl?: string;
}

export interface ITourAccessPointDataExtended extends ITourAccessPointData {
  id: number;
  name: string;
  type: string;
  sequence: number;
}

export interface ITourUnit {
  unitId: string;
  sequence: number;
}

export interface ITourUnitData {
  isActive: boolean;
  description?: string;
  directions?: string;
  imageUrl?: string;
  details?: string;
}

export interface ITourUnitDataExtended extends ITourUnitData {
  unitId: string;
  name: string;
  isOccupied: boolean;
  id?: number;
}

export interface ITourUnitDataExtendedUnit extends ITourUnitDataExtended {
  id: number;
}

export interface ITour {
  id: number;
  communityId: number;
  allowUnitsFromPMS: boolean;
  duration: TourDuration;
  createdAt: Date;
  updatedAt: Date;
  accessPoints: ITourAccessPointDataExtended[];
  units: ITourUnitDataExtendedUnit[];
  availabilitySchedules: IAvailabilitySchedule[];
}

export interface ITourUnitCreate extends ITourUnit, ITourUnitData {}

export interface ITourCreate {
  allowUnitsFromPMS: boolean;
  accessPoints: ITour['accessPoints'];
  units: ITourUnitCreate[];
  duration: TourDuration;
  availabilitySchedules: IAvailabilitySchedule[];
}

export interface ITourUpdate extends ITourCreate {
  id: ITour['id'];
}

export interface ITourUpdatePayload {
  allowUnitsFromPMS: boolean;
  duration: TourDuration;
  availabilitySchedules: IAvailabilitySchedule[];
}

export enum BookedTourStatus {
  Completed = 'COMPLETED',
  NotCompleted = 'NOT_COMPLETED',
  Future = 'FUTURE',
}

export interface IAccessPointAccessed {
  id: number;
  name: string;
}

export interface IUnitAccessed {
  unitId: string;
  name?: string;
}

export interface IBookedTour {
  id: number;
  visitorName: string;
  visitorPhone: string;
  visitorEmail: string;
  startTime: string; // ISO
  status: BookedTourStatus;
  accessPointsAccessed: IAccessPointAccessed[];
  unitsAccessed: IUnitAccessed[];
}
