import React, { useCallback } from 'react';
import { Button, Grid, Select, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';

import { getInitialSchedule } from './utils';
import { ScheduleItem } from './ScheduleItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { TourDuration } from '../app/domain/ITour';

export interface IAvailabilityHours {
  start: string; // "08:00" - 24-hour format
  durationMinutes: number;
}
export interface IAvailabilitySchedule {
  id: number;
  days: number[]; // weekdays 0-6
  hours: IAvailabilityHours;
}

export interface ScheduleProps {
  name?: string;
  schedules?: IAvailabilitySchedule[];
  onChange: (schedules: IAvailabilitySchedule[]) => void;
  duration: TourDuration;
  onChangeDuration: (duration: TourDuration) => void;
  errorMessage?: string;
}

export const ScheduleV2: React.FC<ScheduleProps> = ({
  name = 'Schedule',
  schedules = [getInitialSchedule(0)],
  onChange,
  duration,
  onChangeDuration,
  errorMessage,
}) => {
  const classes = useStyles();

  const addSchedule = useCallback(() => {
    onChange([...schedules, getInitialSchedule(schedules.length)]);
  }, [onChange, schedules]);

  const removeSchedule = useCallback(
    (scheduleId: number) => {
      onChange(schedules.filter((schedule) => schedule.id !== scheduleId));
    },
    [onChange, schedules],
  );

  const onHandleDurationChange = useCallback(
    (event: any) => {
      onChangeDuration(event.target.value);
    },
    [onChangeDuration, duration],
  );

  return (
    <Grid className={classes.root}>
      {schedules.map((schedule) => (
        <ScheduleItem
          key={schedule.id}
          name={name}
          removeSchedule={() => removeSchedule(schedule.id)}
          schedule={schedule}
          onChange={(schedule) => {
            onChange(schedules.map((s) => (s.id === schedule.id ? schedule : s)));
          }}
        />
      ))}

      <Button variant="text" onClick={addSchedule}>
        <Add className="w-4 h-4 mr-2" />
        Add {name.toLowerCase()}
      </Button>

      <Grid container spacing={1}>
        <Grid item xs={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Tour Duration</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={duration}
              label="tour_duration"
              onChange={onHandleDurationChange}
            >
              <MenuItem value={TourDuration.HALF_HOUR}>30 min</MenuItem>
              <MenuItem value={TourDuration.ONE_HOUR}>1 hour</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Typography color="error">{errorMessage}</Typography>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '0.5rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    gap: '1rem',
  },
}));
